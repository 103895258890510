import { CommonNames } from './scripts/common-names';

(async () => {
    while (true) {
        let emailElement = document.getElementById("username");
        let cursorElement = document.getElementById("cursor");

        let next = getRandomizedEmail();

        for (let index = 0; index < next.length; index++) {
            emailElement.innerText += next[index];
            await delay(150);
        }
        cursorElement.className = "blink cursor";
        await delay(1500);
        cursorElement.className = "cursor";

        for (let index = next.length; index >= 0; index--) {
            emailElement.innerText = next.substring(0, index);
            await delay(50);
        }
    }
})();

function getRandomizedEmail(): string {
    let name = CommonNames[Math.floor(Math.random() * CommonNames.length)];
    return name.toLowerCase();
}

function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

